<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-tree-table
          ref="typeTable"
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="typeTable.columns"
          :data="typeTable.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="itemTable"
          title="일상점검항목 목록"
          :columns="itemTable.columns"
          :data="itemTable.data"
          :merge="itemTable.merge"
          selection="multiple"
          :editable="editable"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="true"
          rowKey="checkItemId"
        >
          <template slot="table-chip">
            <q-chip outline square icon="push_pin" color="blue">
              {{ $language("설비유형") +  " : " + rowTypeName}}
            </q-chip>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemName'">
              <c-text-column
                :editable="editable"
                :col="col"
                :props="props"
                @datachange="datachange(props, col)"
              >
              </c-text-column>
              <span>
                <div>
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        v-if="editable"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && itemTable.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn
                v-if="editable && updateMode && itemTable.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="itemTable.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-daily-item',
  data() {
    return {
      typeTable: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '720px'
      },
      itemTable: {
        columns: [],
        height: '120px',
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        dailyFlag: 'Y'
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      updateUrl: '',
      checkUrl: '',
      useFlagItems: [],
      checkMethodItems: [],
      isSave: false,
      isDelete: false,
      updateMode: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
      },
      rowTypeName: '',
      rowCheckItemName: '',
      tempInsGridDetail: {
        equipmentTypeCd:  '',
        plantCd:  '',
        equipmentCheckTypeCd:  '',
        checkItemNo:  '',
        checkItemNm:  '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.daily.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.daily.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.daily.delete.url;

      this.$comm.getComboItems('MDM_CHECK_CYCLE_CD').then(_result => {
        this.itemTable.columns = [
          {
            required: true,
            name: 'checkItemPartName',
            field: 'checkItemPartName',
            label: '부품명',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            btns: [
              { label: '', icon: 'add', color: 'orange', tooltip: this.$language('부품별 점검항목 추가') },
            ],
            style: 'width:250px',
            type: 'custom',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkItemMethod',
            field: 'checkItemMethod',
            label: '점검방법',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkStandard',
            field: 'checkStandard',
            label: '점검기준',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemCycleCd',
            field: 'checkItemCycleCd',
            label: '주기',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            style: 'width:70px',
            type: 'number',
            align: 'left',
            sortable: false,
          },
        ]
        this.itemTable.merge =  [
          { index: 0, colName: "checkItemPartName" },
        ]
      })
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.typeTable.data = _result.data;
        this.updateMode = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeName);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.updateMode = true;
      // 설비유형별 점검항목 조회
      this.$http.url = this.$format(this.itemDetailUrl, this.key.equipmentTypeCd, this.key.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.itemTable.data = this.$_.clone(_result.data);
      },);
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '설비유형을 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.itemTable.data.push({
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: '',
        checkItemPartName: '',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.itemTable.data.length == 0 ? 1 : this.itemTable.data.length * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.itemTable.data = this.$_.reject(this.itemTable.data, { checkItemId: item.checkItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      if (this.$comm.validTable(this.itemTable.columns, this.itemTable.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd, equipmentTypeName: this.rowTypeName })
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.itemTable.data.splice(props.rowIndex+1, 0, {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: '',
        checkItemPartName: props.row.checkItemPartName,
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: Number(props.row.sortOrder),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
  }
};
</script>
